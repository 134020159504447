import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useRef, Fragment } from "react"
import { Fade } from "react-reveal"
import {
	faCheckCircle,
	faInfoCircle,
	faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../../../components/seo"

const beforeAfter = [
	{
		before: "Gehört nicht zu den Top 3 % aller Websites",
		after: <>Gehört zu den Top 3 % aller Websites</>,
		beforeInfo: (
			<>
				Websites die den Core Web Vitals Test bestanden haben (
				<a
					className="underline hover:text-primary"
					target="_blank"
					href="https://pagespeed.web.dev/report?url=https%3A%2F%2Fwww.medarmada.de%2F"
				>
					hier testen
				</a>
				) gehören nach einer{" "}
				<a
					className="underline hover:text-primary"
					target="_blank"
					href="https://www.searchmetrics.com/de/knowledge-hub/studien/core-web-vitals-studie-april-2021/"
				>
					Studie von April 2021
				</a>{" "}
				zu den Top 3 %.
			</>
		),
		category: "Technik",
	},
	{
		before: (
			<>
				Performancewert Mobil: <span className="bg-red-400">44</span> von 100
			</>
		),
		beforeInfo: (
			<>
				<a
					target="_blank"
					className="underline hover:text-primary"
					href="https://pagespeed.web.dev/report?url=https%3A%2F%2Fcivitalis.eu%2F"
				>
					Hier selbst testen
				</a>
				<p>
					Durch verschiedene Faktoren kann das Ergebnis beim selbst Testen von
					dem hier genannten Ergebnis leicht abweichen.
				</p>
			</>
		),
		after: (
			<>
				Performancewert Mobil: <span className="bg-green-400">90+</span> von 100
			</>
		),

		category: "Technik",
	},
	{
		before: (
			<>
				Performancewert Desktop: <span className="bg-yellow-400">80</span> von
				100
			</>
		),
		beforeInfo: (
			<>
				<a
					target="_blank"
					className="underline hover:text-primary"
					href="https://pagespeed.web.dev/report?url=https%3A%2F%2Fcivitalis.eu%2F"
				>
					Hier selbst testen
				</a>
				<p>
					Durch verschiedene Faktoren kann das Ergebnis beim selbst Testen von
					dem hier genannten Ergebnis leicht abweichen.
				</p>
			</>
		),
		after: (
			<>
				Performancewert Desktop: <span className="bg-green-400">90+</span> von
				100
			</>
		),

		category: "Technik",
	},

	{
		before: <>Nicht für Mobilgeräte optimiert</>,
		beforeInfo: (
			<>
				Laut Google nicht für Mobilgeräte optimiert:{" "}
				<a
					target="_blank"
					className="underline hover:text-primary"
					href="https://search.google.com/test/mobile-friendly/result?id=NKkZJ2IhSg-wy639BtPAfA"
				>
					Hier selbst testen
				</a>
			</>
		),
		after: <>Von Grund auf für Mobilgeräte optimiert</>,

		category: "Technik",
	},

	{
		before: <>Social Media Teilbarkeit nicht optimiert</>,
		beforeInfo: (
			<>
				Wie sieht es aus, wenn man ein Link der Website auf Social Media teilt?{" "}
				<a
					target="_blank"
					className="underline hover:text-primary"
					href="https://socialsharepreview.com/?url=https://civitalis.eu/"
				>
					Hier selbst testen
				</a>
			</>
		),
		after: <>Social Media Teilbarkeit optimiert</>,

		category: "Technik",
	},

	{
		before: <>Basiert auf WordPress</>,
		beforeInfo: (
			<>
				Muss regelmäßig wegen der Sicherheit aktualisiert werden, das kann dazu
				führen, dass Plugins kaputt gehen (
				<a
					target="_blank"
					className="underline hover:text-primary"
					href="https://civitalis.eu/?page_id=7264"
				>
					siehe Kontaktseite
				</a>
				).
			</>
		),
		after: <>Basiert auf der modernen Jamstack Architektur</>,
		afterInfo: (
			<>
				Wir basieren unsere Websites auf der{" "}
				<a
					target="_blank"
					className="underline hover:text-primary"
					href="https://flanke7.de/2021/07/21/jamstack/"
				>
					modernen Jamstack Architektur
				</a>
				. Diese bietet deutliche Vorteile vor allem im Bezug auf
				Geschwindigkeit, Skalierbarkeit und Sicherheit.
			</>
		),
		category: "Technik",
	},

	{
		before: <>WordPress ist Hackanfällig</>,
		beforeInfo: (
			<>
				Veraltete Versionen machen WordPress anfällig für Hacker (
				<a
					target="_blank"
					className="underline hover:text-primary"
					href="https://civitalis.eu/?page_id=7264"
				>
					Version von civitalis nicht aktuell
				</a>
				)
			</>
		),
		after: <>Nahezu unhackbar</>,
		afterInfo:
			"Auf Grund der Jamstack Architektur ist der Admin Login von der Website getrennt, sodass vor Hackern keine offene Angriffsfläche besteht.",
		category: "Technik",
	},

	{
		before: <>Keine Keyword Optimierung</>,
		beforeInfo: (
			<>
				Beispiel Keyword: "beratung öffentlicher sektor berlin"{" "}
				<a
					target="_blank"
					className="underline hover:text-primary"
					href="https://www.keyword-tools.org/google-ranking-live-check/?keyword=beratung%20%C3%B6ffentlicher%20sektor%20berlin&domain=https://civitalis.eu/&searchengine=google.de&analysis=true"
				>
					Civitalis ist nicht in den Top 100
				</a>{" "}
				, Platz 1 ist PD
			</>
		),
		afterRows: 2,
		after: (
			<>
				<p>
					Wir führen für Sie eine komplette SEO Optimierung mit Keyword
					Recherche durch und sorgen dafür, dass Sie bei relevanten Keywords
					ranken und dadurch organisch mehr potentielle Websitebesucher gewinnen
					können
				</p>
			</>
		),
		category: "SEO",
	},

	{
		before: <>URLs nicht SEO optimiert</>,
		beforeInfo: (
			<>
				Beispiel:{" "}
				<a
					target="_blank"
					className="underline hover:text-primary"
					href="https://civitalis.eu/?page_id=7264
"
				>
					https://civitalis.eu/
					<span className="bg-yellow-400">?page_id=7264</span>
				</a>
				, "page-id=7264" bietet keine Informationen über den Inhalt
			</>
		),
		category: "SEO",
	},

	{
		before: <>Keine Jobbörse</>,

		after: (
			<>
				<p>
					Ihre Website wird eine eigene Stellenbörse mit Filter- und
					Sortierfunktion haben
				</p>
			</>
		),
		category: "Recruiting",
	},
	{
		before: <>Kein Stellenanzeigentemplate vorhanden</>,
		after: (
			<>
				<a
					target="_blank"
					className="p-2 font-bold text-white rounded-full bg-primary hover:underline"
					href="https://web030-demo.netlify.app/civitalis"
				>
					Beispiel Stellenanzeigentemplate
				</a>
			</>
		),

		category: "Recruiting",
	},

	{
		before: (
			<>Es werden keine organischen Bewerber über die Website generiert</>
		),

		after: (
			<>
				<p>
					Jede Stelle ist Google for Jobs optimiert, sodass Ihre Jobs organisch
					über Google gefunden werden können
				</p>
			</>
		),

		afterInfo:
			"Google for Jobs ist eine in Google integrierte Stellenbörse, auf der man erscheint, wenn die Stellenanzeigen auf der eigenen Website richtig programmiert sind.",
		category: "Recruiting",
	},

	{
		before: "Die Website wird über WordPress verwaltet",
		beforeInfo:
			"WordPress bietet die Freiheit alles zu ändern, was Sie wollen. Jedoch besteht dabei auch die Gefahr viel kaputt zu machen.",
		beforeRows: 5,

		after: <>Verwaltung über unsere Firmen Website App</>,
		category: "Verwaltung",
	},
	{
		after: "Einfaches und intuitives CMS, ohne Gefahr etwas kaputt zu machen",
		afterInfo:
			"CMS (Content Management System) - ermöglicht die Bearbeitung von Inhalten auf der Website.",
		category: "Verwaltung",
	},

	{
		after: "Fehlerfreie Erstellung und Verwaltung von Jobs für Google for Jobs",

		afterInfo: "Optionale Schnittstellen sind möglich (Bsp. Arbeitsagentur)",
		category: "Verwaltung",
	},

	{
		after: "Integriertes Analytics-Dashboard",
		afterInfo:
			"Bekommen Sie schnell einen Überblick über aktuelle Kennzahlen der Website ohne den Programmierer mal wieder nach dem Google Analytics Zugang zu fragen.",
		category: "Verwaltung",
	},

	{
		after: "Social Media Schnittstellen",
		afterInfo:
			"Posten Sie Ihre Stellen direkt aus der Firmen Website App auf Ihre Social Media Kanäle. Behalten Sie zudem durch unser Social Media Performance Board die Übersicht über Ihre Follower, Fans und Klickraten.",
		category: "Verwaltung",
	},
]

const Page = () => {
	return (
		<div className="w-full min-h-screen bg-primary-50">
			<Seo
				title="Angebot für Civitalis GmbH - Website"
				description="Angebot für Civitalis GmbH - Website"
				noIndex
			/>
			<div className="px-2 pt-10 mx-auto sm:px-6 sm:max-w-2xl lg:px-8 lg:max-w-[1500px]">
				<Link className="hover:underline" to="/angebot/4894">
					Zurück zu den Angeboten
				</Link>
			</div>
			<div className="px-2 mx-auto sectionpadding sm:px-6 sm:max-w-2xl lg:px-8 lg:max-w-[1500px]">
				<StaticImage
					quality={100}
					width={400}
					src="../../../images/logo.png"
					alt=""
					placeholder=""
					loading="eager"
					className="mx-8 mb-4 sm:mx-auto lg:float-right"
				/>
				<h1 className="mb-16 font-bold h1 text-primary">
					Relaunch von{" "}
					<a
						target="_blank"
						className="underline hover:text-primary-700"
						href="https://civitalis.eu/"
					>
						civitalis.eu
					</a>
				</h1>

				<Fade up>
					<table className="mx-auto overflow-hidden rounded shadow xl:col-span-2">
						<tr className="text-xl">
							<th className="flex items-center w-full gap-2 px-4 py-3 text-left border-l sm:text-center sm:w-1/2 sm:table-cell bg-gray-50 sm:px-8">
								<FontAwesomeIcon
									icon={faTimesCircle}
									className={"text-red-500 sm:!hidden"}
									size="lg"
								/>
								Aktueller Stand{" "}
								<a
									target="_blank"
									href="https://civitalis.eu/"
									className="underline text-primary hover:text-primary-600"
								>
									civitalis.eu
								</a>
							</th>
							<th className="flex items-center w-full gap-2 px-4 py-3 text-left sm:text-center sm:w-1/2 sm:table-cell bg-green-50">
								<FontAwesomeIcon
									icon={faCheckCircle}
									className={"text-green-500 sm:!hidden"}
									size="lg"
								/>
								<div>
									Nach dem Relaunch
									<p className="text-sm font-bold">
										Wie bei PD, bloß besser 😉
									</p>
								</div>
							</th>
						</tr>
						{beforeAfter.map((item, index) => (
							<Fragment key={index}>
								{item.category !== beforeAfter[index - 1]?.category && (
									<tr className="bg-primary-100 text-primary-900">
										<td
											className="block w-full px-4 py-2 text-xl font-bold text-center sm:table-cell"
											colSpan={2}
										>
											<h3>{item.category}</h3>
										</td>
									</tr>
								)}
								<tr>
									{item.before && (
										<td
											className="block w-full px-4 py-4 align-top bg-white border-t border-r sm:w-1/2 sm:table-cell sm:px-8"
											rowSpan={item.beforeRows || 1}
										>
											<Fade down delay={index * 10 + 200}>
												<Cell
													text={item.before}
													info={item.beforeInfo}
													icon={item.icon || faTimesCircle}
													iconClassName="text-red-500"
												/>
											</Fade>
										</td>
									)}
									{item.after && (
										<td
											className={
												"px-4 sm:px-8 block w-full sm:table-cell py-4 bg-green-50 sm:w-1/2 border-t align-top"
											}
											rowSpan={item.afterRows || 1}
										>
											<Fade down delay={index * 10 + 400}>
												<Cell
													text={item.after}
													info={item.afterInfo}
													icon={item.icon || faCheckCircle}
													iconClassName="text-green-500"
												/>
											</Fade>
										</td>
									)}
								</tr>
							</Fragment>
						))}
					</table>
				</Fade>

				<Fade right>
					<div className="max-w-lg p-5 mx-auto mt-10 bg-white rounded shadow">
						<h2 className="text-2xl font-bold text-primary">€ Angebot</h2>
						<p className="text-xl font-bold text-primary">
							Relaunch der civitalis.eu
						</p>
						<div className="mt-4">
							<h3 className="text-lg font-bold">Honorar VAR 1</h3>
							<p>
								<span className="font-bold text-secondary">9.800 €</span> +{" "}
								MwSt. - 100 % bei Auftragserteilung fällig
							</p>
							<h3 className="mt-4 text-lg font-bold">Honorar VAR 2</h3>
							<p>
								<span className="font-bold text-secondary">11.270 €</span> +{" "}
								MwSt. - 50 % bei Aufragserteilung, 50 % nach Fertigstellung
							</p>
							<h3 className="mt-4 text-lg font-bold">Go-Live</h3>
							<p>
								Innerhalb von{" "}
								<span className="font-bold text-secondary">15 Werktagen</span>{" "}
								ab Auftragserteilung
							</p>
							<h3 className="mt-4 text-lg font-bold">Warum ein Relaunch?</h3>
							<p>
								Über 95 % aller qualifizierten Bewerber werden Ihre Website
								besuchen
							</p>
						</div>
						<div className="flex flex-wrap items-center gap-2 mt-8 sm:flex-nowrap">
							<a target="_blank" href="https://webentwicklung-berlin-030.de/">
								<img
									className="flex-1"
									src="https://webentwicklung-berlin-030.de/Logo.svg"
									alt=""
								/>
							</a>
							<a target="_blank" href="https://karriere-website.kaufen/">
								<img className="flex-1" src="/kws_logo.svg" alt="" />
							</a>
						</div>
					</div>
				</Fade>
				<Fade up>
					<div className="flex flex-wrap justify-center gap-8 mt-16">
						<Link
							to="/angebot/4894/recruitervermittlung"
							className="btn btn-secondary"
						>
							Senior Recruiter Vermittlung
						</Link>
						<Link
							to="/angebot/4894/recruiter-mieten"
							className="btn btn-secondary"
						>
							Recruiter mieten
						</Link>
					</div>
				</Fade>
			</div>
		</div>
	)
}

export default Page

const Cell = ({ text, info, icon, iconClassName }) => {
	const randomID = useRef(Math.floor(Math.random() * 100000))
	const [open, setopen] = useState(false)

	return (
		<>
			<div className="flex gap-2">
				<FontAwesomeIcon
					icon={icon}
					className={" " + iconClassName}
					size="lg"
				/>

				<div className="flex-grow">{text}</div>

				{info && (
					<button
						aria-expanded={open}
						aria-controls={`panel-${randomID.current}`}
						type="button"
						onClick={() => {
							setopen((prev) => !prev)
						}}
					>
						<FontAwesomeIcon
							icon={faInfoCircle}
							className="text-gray-400"
							size="lg"
						/>
					</button>
				)}
			</div>
			<div
				id={`panel-${randomID.current}`}
				className={"leading-tight px-7 mt-2 text-xs " + (open ? "" : "hidden")}
			>
				<div>{info}</div>
			</div>
		</>
	)
}
